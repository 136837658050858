
.carousel-caption
{
  bottom:-5px;
}

.carousel-indicators
{
  bottom:-12px;
  visibility: hidden;
}

.home {
  background: #343A40;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  height: 100vh;
  font-size: calc(6px + 2vmin);
  padding-left: 30px;
  padding-right: 30px;
  font-family: "arial";
}

.btn-flat {
  background-color: #007bff;
  color: white !important;
  font-weight: 800;
}
