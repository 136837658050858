
.subscribeBox 
{
  text-align: left;  
  padding-top: 100px;
}
form.subscribe {
  max-width: 500px;
  font-size: 22px !important;
  border: white;
  border-color: white;
  border-style: double;
}
form.subscribe button{
  justify-content: left;
  background-color: #007bff;
  color:white;  
  margin: 10px;
}