div.blogpost {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  padding-top:25px;
}
div.bookpromo {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  padding-top:70px;
  padding-left:25px;
  padding-right:25px;
  padding-bottom:25px;
  background-color:whitesmoke;
}
div.blogContent {  
  background-color: #f7f7f7;
}
figure {
  text-align: center;
}

.react-bootstrap-drawer-toc-header {
  margin-top: 1rem;
  font-weight: bold;
}
.react-bootstrap-drawer {    
  background-color: #ffff;
  color:lightseagreen;
  position: sticky !important;
  z-index: 0;
  height: calc(100vh - 4rem);
  margin-top: 0px;
  border: 0px solid #ececec;
}
.center-cropped {
  width: 319px;
  height: 210px;
  background-position: center center;
  background-repeat: no-repeat;
}
.react-bootstrap-drawer-toc {
  height: 100% !important;
  overflow: hidden;
  margin-right: -15px;
  padding-right: calc(15px + 1rem);
}
.blog-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  color: white;
  padding:10px;
  opacity: 0.8;
  font-weight: bolder;
}
.offset {
  margin-left:10px;
  margin-top: 50px;
  text-align: left;
}
.Sidebar {
  height: 100%;
  position: absolute;
  overflow: 'scroll initial';
  position:absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
  background-color: #343a40;
  background: none;
  color: #0000;
  margin-top: 50px;
}
.react-bootstrap-drawer-toc .nav-link
{  
  color: lightseagreen !important;
  padding-top:0px;
  padding-left:0px;
  padding-bottom: 8px;
}

hr.rounded {
  border-top: 8px solid #bbb;
  border-radius: 5px;
}

div.blogList a {
    color:black !important;
}

. .control-prev.control-arrow {
  left: 0; }
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000; }
.carousel .control-next.control-arrow {
  right: 0; }
  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000; }

div.quoteSlider {  
  background-color: #fff;
  border-bottom-width: thin;
  border-bottom-color: black;
  border-style: solid;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  font-weight: bold;
  color: black;
}

.verticalImage {  
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 50vh;
}


div.blogpost img
{  
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70vh;
}

div.blogpost a
{  
  color:lightseagreen !important;
}

div.blogpost h4
{  
  text-transform: uppercase;
}

div.blogpost h5
{  
  text-transform: uppercase;
}
.blogCard {
  width: '18rem';  
}
.navbar {
  text-transform: uppercase;
}
