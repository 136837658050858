
.meImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  
  .about {
    font-weight: 450;
    font-size: 22px;
    font-family: "roboto";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }