
.navbar-brand,
.navbar-nav .nav-link {
  font-weight: 800;
  color: whitesmoke !important;
}

.navbar-brand:hover,
.navbar-nav:hover .nav-link:hover {
  font-weight: 800;
  color: darkgray !important;
}

