.hero-image {
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .nomatchBanner {
    background-image: url("../assets/nomatch.jpg");
  }
  .porfolioBanner {
    background-image: url("../assets/portfolio.jpg");
  }
  .blogBanner {
    background-color:#343A40;
  }
  .blogListBanner {
    background-image: url("../assets/blogheader.jpg");
  }
  .contactBanner {
    background-image: url("../assets/contact.jpg");
  }
  
  .kindleBanner {
    background-image: url("../assets/kindle.jpeg");
  }
  .bookBanner {
    background-image: url("../assets/books.jpg");
  }