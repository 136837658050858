.video {
  min-width: 200px;
  padding-bottom: 10px;
}

.title {
  text-transform: uppercase;
  text-align: left;
}

.quote {
  font-weight: 500;
  font-size: 22px !important;
  border: black;
  border-color: black;
  border-style: double;
  padding: 10px;
}

a.nounderline:link {text-decoration:none;}
a.nounderline:visited {text-decoration:none;}
a.nounderline:hover {text-decoration:none;}

.required {
  color: red;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-size: 64px;
  font-weight: bold;
}
h2 {
  font-size: 48px;
  font-weight: bold;
}
h3 {
  font-size: 36px;
  font-weight: bold;
}
h4 {
  font-size: 24px;
  font-weight: bold;
}
h5 {
  font-size: 20px;
  font-weight: bold;
}
h6 {
  font-size: 18px;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
